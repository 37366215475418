<template>
  <b-input-group prepend="Date" class="filter mr-2 mb-2">
    <template #prepend>
      <b-input-group-text>
        <!-- <i class='bx' :class="icon" ></i>&nbsp; -->
        {{$t(label)}}
      </b-input-group-text>
    </template>

    <template v-if="type == 'date'">
      <b-form-datepicker 
        v-model="internalValue[0]" 
        v-bind="labels || {}"
        nav-button-variant="dark"
        :hide-header="true"
        :locale="$i18n.locale" 
        :date-format-options="dateFormat" 
        :placeholder="$t('filters.date.from')"
      ></b-form-datepicker>
      <div class="input-group-append input-group-prepend">
        <div class="input-group-text">-</div>
      </div>

      <b-form-datepicker 
        v-model="internalValue[1]" 
        v-bind="labels || {}"
        nav-button-variant="dark"
        :hide-header="true"
        :locale="$i18n.locale" 
        :date-format-options="dateFormat" 
        :placeholder="$t('filters.date.to')"
      ></b-form-datepicker>
    </template>

    <template v-if="type == 'select'">
      <v-select 
        v-if="multiple"
        v-model="internalValue" 
        v-bind="{multiple, reduce}"
        :options="options | transMap | featureFilter"
        :deselect-from-dropdown="multiple"
      >
        <template #option="option" v-if="$attrs.name == 'status'">
          <span
            :class="`text-${statusVariant(option)}`">
            <i v-if="option.icon" :class="['small','bx',option.icon]" />
            {{option.label}}
          </span>
        </template>
        <template #selected-option="option" v-if="$attrs.name == 'status'">
          <span
            :class="`text-${statusVariant(option)}`">
            <i v-if="option.icon" :class="['small','bx',option.icon]" />
            {{option.label}}
          </span>
        </template>
      </v-select>
      <v-select 
        v-else
        v-model="internalValue[0]"
        v-bind="{ reduce}"
        :clearable="false"
        :options="options | transMap"
      />
    </template>

    <template v-if="type == 'relation'">
      <select-item
        v-model="internalValue[0]"
        v-bind="{resourceName: resource, scope}"
        :clearable="false"
      />
    </template>

    <template v-if="type == 'country'">
      <select-country
        v-model="internalValue[0]"
        :clearable="false"
      />
    </template>

    <template v-if="type == 'boolean'">
      <b-input-group-prepend v-if="!flag">
        <b-input-group-text class="bg-white">
          <b-form-checkbox 
            switch
            :checked="!!internalValue[0]"
            v-model="internalValue[0]"
          />
        </b-input-group-text>
      </b-input-group-prepend>
    </template>

    <b-input-group-append><b-button variant="danger" @click="$emit('remove')"><i class='bx bx-trash' ></i></b-button></b-input-group-append>
  </b-input-group>
</template>

<script>
import SelectItem from "@/components/Form/SelectItem";
import SelectCountry from "@/components/Form/SelectCountry";

export default {
  components: {
    SelectItem,
    SelectCountry,
  },
  props: {
    value: {
      type: [Array],
      default: null
    },
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    multiple: Boolean,
    reduce: {
      type: Function,
      default: item => item.code
    },
    options: Array,
    default: Boolean,
    flag: Boolean,
    resource: String,
    scope: [String,Array],
  },
  watch: {
    internalValue(value) {
      this.$emit('input',value)
    }
  },
  created() {
    if((this.default || this.flag) && this.initial) {
      this.$emit('input',this.internalValue)
    }
  },
  data() {
    return {
      initial: !this.value,
      internalValue: this.value || this.getDefaultValue(),
      dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
    }
  },
  computed: {
    labels() {
      return this.$i18n.messages[this.$i18n.locale].calendar
    }
  },
  methods: {
    getDefaultValue() {
      if(this.type === 'date') {
        return [null,null]
      } else if(this.type === 'boolean') {
        return [!!this.default]
      } else {
        return []
      }
    },
    statusVariant(option) {
      switch(true) {
        case !!option.variant:
          return option.variant
        case option.code <= 0:
          return 'muted'
        case option.code >= 100:
          return 'success'
        default:
          return 'info'
      }
    }
  }
}
</script>