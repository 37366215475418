<template>
  <b-modal 
    ref="modal" 
    :no-close-on-backdrop="!finished"
    :no-close-on-esc="!finished"
    :hide-header-close="!finished"
  >
    <template #modal-title>
      {{$t(`actions.${action.name}.processing`,{items: $tc(`${resource.plural}.pluralize`,items.length)})}}
    </template>

    <b-progress :value="progress" :max="items.length" :animated="!finished" variant="info" />

    <b-alert variant="success" :show="finished" class="mt-4">
      {{$t(`actions.${action.name}.finished`,{items: $tc(`${resource.plural}.pluralize`,items.length)})}}
    </b-alert>

    <template #modal-footer>
      <b-button :disabled="!finished" @click="close">
        {{$t('common.ok')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"

export default {
  name: 'Action',
  extends: Modal,
  props: {
    action: {
      type: Object,
      required: true,
    },
    resource: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.run()
  },
  data() {
    return {
      progress: 0,
      finished: false,
    }
  },
  methods: {
    async run() {
      for(let item of this.items) {
        await this.action.action(item)
        this.progress++
      }
      this.finished = true
    }
  }
}
</script>